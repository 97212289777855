<template>
  <label :class="{_checked: checked, _disabled: disabled}"
         class="checkbox">
    <input type="checkbox"
           :checked="checked"
           @click="onInput">
    <span class="checkbox__mark">
    </span>
    <slot :label="label" :$attrs="$attrs">
      <span v-if="label" class="checkbox__text">
        <span v-if="isAttributeTrue($attrs.required)" class="input__required">*</span>
        {{ label }}
      </span>
    </slot>
  </label>
</template>

<script>
  export default {
    model: {
      prop: 'checked'
    },
    props: {
      disabled: Boolean,
      checked: {
        type: Boolean,
        default: false
      },
      label: {
        type: String
      }
    },
    methods: {
      onInput(event) {
        if (this.disabled) return
        this.$emit('input', event.target.checked)
      },
      isAttributeTrue(attr) {
        return attr || attr === ''
      }
    }
  }
</script>

<style lang="less">

</style>
