<template>
  <label class="input"
         :class="selector"
  >
    <div v-if="label"
         class="input__label"
    >
      <span>{{ label }}
        <span v-if="isAttributeTrue($attrs.required)"
              class="input__required"
        > *</span>
      </span>
    </div>

    <slot :focusHandler="focusHandler" :blurHandler="blurHandler">
    <textarea v-if="textarea"
              v-bind="$attrs"
              @change="onChange"
              @input="onInput"
              @focus="onFocus"
              @blur="onBlur"
              :value="value"
              :style="style"
              ref="input"
              class="input__input"
              key="textarea"
    />

      <imask-input v-else-if="mask"
                   ref="input"
                   :type="type"
                   v-bind="[$attrs,mask]"
                   :lazy="!(focus || filled)"
                   @change="onChange"
                   @input="onInput"
                   @focus="onFocus"
                   @blur="onBlur"
                   @hook:mounted="onMaskMounted"
                   :value="value"
                   :style="style"
                   class="input__input"
                   key="mask"
      />

      <input v-else
             v-bind="$attrs"
             @change="onChange"
             @input="onInput"
             @focus="onFocus"
             @blur="onBlur"
             :value="value"
             :style="style"
             ref="input"
             :type="type"
             class="input__input"
             key="input"
      >
    </slot>

    <InputError :value="error"/>
  </label>
</template>

<script>
  import InputError from './BaseInputError'
  import {IMaskComponent} from 'vue-imask'

  export default {
    name: 'NewBaseInput',
    inheritAttrs: false,
    components: {
      InputError,
      'imask-input': IMaskComponent
    },
    props: {
      value: null,
      label: null,
      type: {
        type: String,
        default: 'text'
      },
      textarea: {
        type: Boolean,
        default: false
      },
      overTitle: {
        type: Boolean,
        default: true
      },
      leftSpace: Number,
      error: {
        type: [String, Array]
      },
      mask: null
    },
    data() {
      return {
        focus: false,
        isVisiblePassword: false
      }
    },
    computed: {
      isPassword() {
        return this.type === 'password'
      },
      filled() {
        return !!this.value || Number.isFinite(this.value)
      },
      selector() {
        return {
          '_over': this.overTitle,
          '_focus': this.focus,
          '_filled': this.filled,
          '_error': this.error,
          '_password': this.isPassword
        }
      },
      style() {
        let s = {}

        if (this.leftSpace) {
          s.paddingLeft = (17 + this.leftSpace) + 'px'
        }

        return s
      }
    },
    methods: {
      focusHandler() {
        this.onFocus()
      },
      blurHandler() {
        this.onBlur()
      },
      onChange(event) {
        this.$emit('change', event.target.value)
      },
      onFocus() {
        this.focus = true
        this.$emit('focus')
      },
      onBlur() {
        this.focus = false
      },
      onInput(event) {
        if (this.mask) {
          if (this.$refs.input.maskRef.masked.rawInputValue) {
            this.$emit('input', event)
          } else {
            this.$emit('input', null)
          }
        } else {
          this.$emit('input', this.formatValue(event.target.value))
        }
      },
      isAttributeTrue(attr) {
        return attr || attr === ''
      },
      formatValue(value) {
        if (this.$attrs.type === 'number') {
          value = value.replace(',', '.')
          value = parseFloat(value)
        }

        return value
      },
      onMaskMounted() {
        this.$refs.input._onAccept()
      }
    }
  }
</script>

<style lang="less">

</style>
