<template>
  <transition name="error">
    <ul v-if="value" class="input-error">
      <li v-for="(error, i) in formattedErrors"
          :key="i"
          class="input-error__item">
        * {{error}}
      </li>
    </ul>
  </transition>
</template>

<script>
  export default {
    props: {
      value: {
        type: [String, Array],
        default: null
      }
    },
    data() {
      return {}
    },
    computed: {
      formattedErrors() {
        if (Array.isArray(this.value)) return this.value
        if (typeof this.value === 'string') return [this.value]

        return null
      }
    }
  }
</script>

<style lang="less">

</style>
