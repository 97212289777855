<template>
  <div class="select"
       @keyup.down="onKeyupDown"
       :class="selector">

    <!--  TITLE  -->
    <label v-if="label" class="select__title">
      <span>{{ label }}<span v-if="isAttributeTrue($attrs.required)" class="select__required">
        *</span></span>
    </label>

    <div class="select__wrap">
      <div class="select__container" @click.prevent="toggleOpen">

        <!--  MULTIPLE  -->
        <div v-if="multiple"
             class="select__main">
          <div v-for="(option, i) in selectedValue"
               :key="i"
               class="select__current">
            <slot name="current" :option="option">
              <span>{{ getOptionLabel(option) }}</span>
            </slot>
            <span class="select__cancel" @click.prevent.stop="deselect(i)">
              <svg>
                <use xlink:href="#select-ico-close"></use>
              </svg>
            </span>
          </div>
          <input v-if="searchable"
                 type="text"
                 v-model="searchValue"
                 v-bind="$attrs"
                 ref="input"
                 tabindex="0"
                 @input="onSearch"
                 @blur="onBlur"
                 autocomplete="off"
                 class="select__input">
        </div>

        <!--  ONE OPTION  -->
        <div v-else
             class="select__main">
          <div v-if="selectedValue && !isOpen" class="select__current">
            <slot name="current" :option="selectedValue">
              <span>{{ getOptionLabel(selectedValue) }}</span>
            </slot>
          </div>
          <!--          {{getOptionLabel(selectedValue) || $attrs.placeholder}}-->
          <input v-if="searchable"
                 type="text"
                 v-model="searchValue"
                 ref="input"
                 @input="onSearch"
                 tabindex="0"
                 autocomplete="off"
                 :placeholder="getOptionLabel(selectedValue) || $attrs.placeholder"
                 class="select__input">
        </div>
        <span v-if="!multiple && selectedValue && cancelable" class="select__cancel"
              @click.prevent.stop="deselect()">
          <svg>
            <use xlink:href="#select-ico-close"></use>
          </svg>
        </span>


        <!--  LOADER  -->
        <span v-show="loadingComputed" class="select__loader">
          Loading...
        </span>

        <!--  ARROW  -->
        <span class="select__arrow">
          <svg>
            <use xlink:href="#select-ico-arrow"></use>
          </svg>
        </span>

      </div>

      <!--  DROPDOWN  -->
      <transition :name="animation"
                  @enter="enter"
                  @after-enter="afterEnter">
        <div v-if="isOpen"
             @keyup.up="onKeyupUp"
             ref="dropdown"
             class="select__dropdown">
          <div class="select__dropdown-scroll-component">
            <div v-if="!computedOptions || !computedOptions.length"
                 class="select__dropdown-placeholder">
              no data
            </div>
            <div v-for="(option, i) in computedOptions"
                 :key="i"
                 class="select__option"
                 :tabindex="i + 1"
                 :class="{
                    _selected: isSelectedOption(option),
                    _disabled: isDisabledOption(option)
                 }"
                 @click="select(option)"
                 @keyup.enter="select(option)">
              <slot name="option"
                    :option="option">
                {{ getOptionLabel(option) }}
              </slot>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <!--  SVGs FOR INCLUDING BY 'USE'  -->
    <div style="display: none !important;">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.9 21.9" id="select-ico-close">
        <path
            d="M14.1,11.3c-0.2-0.2-0.2-0.5,0-0.7l7.5-7.5c0.2-0.2,0.3-0.5,0.3-0.7s-0.1-0.5-0.3-0.7l-1.4-1.4C20,0.1,19.7,0,19.5,0  c-0.3,0-0.5,0.1-0.7,0.3l-7.5,7.5c-0.2,0.2-0.5,0.2-0.7,0L3.1,0.3C2.9,0.1,2.6,0,2.4,0S1.9,0.1,1.7,0.3L0.3,1.7C0.1,1.9,0,2.2,0,2.4  s0.1,0.5,0.3,0.7l7.5,7.5c0.2,0.2,0.2,0.5,0,0.7l-7.5,7.5C0.1,19,0,19.3,0,19.5s0.1,0.5,0.3,0.7l1.4,1.4c0.2,0.2,0.5,0.3,0.7,0.3  s0.5-0.1,0.7-0.3l7.5-7.5c0.2-0.2,0.5-0.2,0.7,0l7.5,7.5c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l1.4-1.4c0.2-0.2,0.3-0.5,0.3-0.7  s-0.1-0.5-0.3-0.7L14.1,11.3z"></path>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5 9" id="select-ico-arrow">
        <path
            d="M4.82010681,4.94554628 L1.04856165,8.81528316 C0.808645822,9.06157228 0.419663431,9.06157228 0.179864076,8.81528316 C-0.0599546918,8.56921314 -0.0599546918,8.17011123 0.179864076,7.92406113 L3.51709927,4.49993527 L0.179961137,1.07592891 C-0.0598576307,0.829759299 -0.0598576307,0.430697227 0.179961137,0.184627208 C0.419779904,-0.0615424026 0.808742883,-0.0615424026 1.04865871,0.184627208 L4.82020387,4.05442384 C4.94011325,4.17751861 5,4.33867714 5,4.49991535 C5,4.66123323 4.93999678,4.82251127 4.82010681,4.94554628 Z"></path>
      </svg>
    </div>

    <InputError :value="error"/>
  </div>
</template>

<script>
  import InputError from './InputError'

  export default {
    components: {
      InputError,
    },
    model: {},
    props: {
      options: {
        type: Array,
        default: () => []
      },
      disableOptions: {
        type: Array,
        default: () => []
      },
      value: {
        type: [String, Number, Array, Object],
        default: null
      },
      textKey: {
        type: String,
        default: 'text'
      },
      label: {
        type: String
      },
      multiple: {
        type: Boolean,
        default: false
      },
      searchable: {
        type: Boolean,
        default: true
      },
      filterable: {
        type: Boolean,
        default: true
      },
      cancelable: {
        type: Boolean,
        default: true
      },
      animation: {
        type: String,
        default: 'dropdown'
      },
      datalist: {
        type: Boolean,
        default: false
      },
      error: {
        type: [String, Array]
      },
      getOptionText: {
        type: Function
      },
      loading: {
        type: Boolean,
        default: false
      },
      overTitle: {
        type: Boolean,
        default: true
      },
    },
    data() {
      return {
        isOpen: false,
        isLoading: false,
        searchValue: ''
      }
    },
    computed: {
      selectedValue() {
        return this.value
      },
      selected() {
        return !!(!this.multiple && this.selectedValue
            || this.multiple && this.selectedValue && this.selectedValue.length)
      },
      selector() {
        return {
          _over: this.overTitle,
          _open: this.isOpen,
          _loading: this.loadingComputed,
          _multiple: this.multiple,
          _selected: this.selected,
          _disabled: this.isAttributeTrue(this.$attrs.disabled),
          _error: this.error,
          _cancelable: this.cancelable,
        }
      },
      computedOptions() {
        if (!this.searchable || !this.filterable || !this.options.length || this.searchValue === '') return this.options
        return this.filter(this.options, this.searchValue)
      },
      loadingComputed() {
        return this.isLoading || this.loading
      }
    },
    methods: {
      //events
      onInput() {
        this.$emit('input', this.innerValue, this.toggleLoading)
      },
      onSearch() {
        this.$emit('search', this.searchValue, this.toggleLoading)
      },
      onBlur() {
        if (this.datalist && this.searchValue.length) {
          const option = this.createOption(this.searchValue)

          this.input(option)
        }

        this.close()
        this.$emit('blur')
      },
      //open/close
      toggleOpen() {
        if (this.isOpen) {
          const input = this.$refs['input']
          if (!input || input !== document.activeElement) this.close()
        } else {
          this.open()
        }
      },
      open() {
        if (this.disabled) return

        this.isOpen = true
        setTimeout(() => {
          document.body.addEventListener('click', this.clickOut)
        })

        const input = this.$refs['input']
        if (input) input.focus()
        this.$emit('focus', this.toggleLoading)
      },
      close() {
        this.isOpen = false

        const input = this.$refs['input']
        if (input) {
          input.blur()
        }

        this.searchValue = ''

        document.body.removeEventListener('click', this.clickOut)
      },
      clickOut(event) {
        let wrap = this.$el.querySelector('.select__wrap') || this.$el

        if (event.target !== this.$el && !wrap.contains(event.target)) {
          this.onBlur()
        }
      },
      input(option) {
        this.setValue(option)
        this.onInput()
      },
      //selection
      select(option) {
        if (this.isDisabledOption(option)) return
        this.input(option)
        this.close()
      },
      deselect(index) {
        if (this.multiple) {
          this.innerValue.splice(index, 1)
        } else {
          this.innerValue = null
          this.searchValue = ''
        }

        this.onInput()
        this.close()
      },
      createOption(value) {
        if (!this.options.length) return value
        if (typeof this.options[0] === 'object' && !Array.isArray(this.options[0])) {

          return {
            datalistCreatedOption: true,
            [this.labelKey]: value
          }
        }

        return value
      },
      //helpers
      getOptionLabel(option) {
        if (this.getOptionText) {
          return this.getOptionText(option)
        }

        if (option instanceof Object) {
          return (option[this.textKey] + '') || ''
        }

        return option
      },
      filter(options, search) {
        search = (search || '').toLowerCase()

        return options.filter((option => {
          let label = this.getOptionLabel(option) + ''

          return (label || '').toLowerCase().indexOf(search) !== -1
        }))
      },
      setValue(option) {
        if (this.multiple) {
          if (this.innerValue.indexOf(option) !== -1) return
          this.innerValue.push(option)
        } else {
          if (this.innerValue === option) return
          this.innerValue = option
        }
      },
      setStartValue(option) {
        if (this.multiple && !Array.isArray(option)) {
          throw new Error('The prop "value" should be Array with prop "multiple"')
        }

        if (!this.cancelable && !option && this.options.length) {
          this.setValue(this.options[0])
          return this.innerValue
        }

        return option
      },
      isSelectedOption(option) {
        if (Array.isArray(this.selectedValue)) {
          return (this.selectedValue.indexOf(option) !== -1)
        }

        return this.selectedValue === option
      },
      isDisabledOption(option) {
        let res = false
        if (this.disableOptions && this.disableOptions.length) {
          res = !this.disableOptions.some(item => item.id === option.id)
        }
        return res
      },
      toggleLoading(toggleValue = null) {
        if (toggleValue === null) return this.isLoading = !this.isLoading
        return this.isLoading = !!toggleValue
      },
      onKeyupDown(e) {
        let focusedEl = e.target.closest('[tabindex]')
        if (!focusedEl) {
          focusedEl = this.$el.querySelector('[tabindex="1"]')
        }

        if (!focusedEl) return
        let index = +focusedEl.getAttribute('tabindex') || 0
        let nextEl = this.$el.querySelector(`[tabindex="${index + 1}"]`)

        if (!nextEl) return
        nextEl.focus()
      },
      onKeyupUp(e) {
        let focusedEl = e.target.closest('[tabindex]')
        if (!focusedEl) return

        let index = +focusedEl.getAttribute('tabindex') - 1
        if (!index) return
        let nextEl = this.$el.querySelector(`[tabindex="${index}"]`)

        if (!nextEl) return
        nextEl.focus()
      },
      isAttributeTrue(attr) {
        return attr || attr === ''
      },
      // appear() {
      //   debugger
      // },
      enter() {
        // debugger
        // this.scrollbar = OverlayScrollbars(this.$refs['dropdown'], {});

      },
      afterEnter() {
        // debugger
        // this.scrollbar = OverlayScrollbars(this.$refs['dropdown'], {});
        // console.log('afterEnter')
      },
    },
  }
</script>

<style lang="less">


</style>
