<template>
  <div :class="selector"
       class="radio-list">
    <div class="radio-list__list">
      <div v-if="all !== false"
           class="radio-list__list-item">
        <label @click.prevent="onChange(null)"
               :class="!value ? '_checked' : null"
               class="radio-list__radio  checkbox">
          <input type="radio"
                 :name="name"
                 :checked="!value"
                 class="checkbox__input">
          <span class="checkbox__mark"></span>
          <span class="checkbox__text">{{ all }}</span>
        </label>
      </div>
      <div v-for="(option, index) in options"
           :key="name + index"
           class="radio-list__list-item">
        <label @click.prevent="onChange(option)"
               :class="isChecked(value, option) ? '_checked' : null"
               class="radio-list__radio  checkbox">
          <input type="radio"
                 :name="name"
                 :checked="isChecked(value, option)"
                 class="checkbox__input">
          <span class="checkbox__mark"></span>
          <span class="checkbox__text" v-html="getOptionLabelInner(option)"/>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
  import getUniqueId from '../helpers/getUniqueId'

  export default {
    props: {
      options: {
        type: Array,
        default: () => []
      },
      value: {
        type: [String, Number, Object]
      },
      columns: {
        type: Boolean,
        default: false
      },
      name: {
        type: String,
        default: () => {
          return 'radio-' + getUniqueId()
        }
      },
      textKey: {
        type: String,
        default: 'name'
      },
      all: {
        type: [String, Boolean],
        default: false
      },
      isChecked: {
        type: Function,
        default: (value, option) => {
          return option.hasOwnProperty('id') && value && option && (value.id === option.id)
              || value === option
        }
      },
      vertical: {
        type: Boolean,
        default: false
      },
      getOptionLabel: Function
    },
    data() {
      return {}
    },
    computed: {
      selector() {
        return {
          _vertical: this.vertical
        }
      }
    },
    methods: {
      onChange(option) {
        this.$emit('input', option)
      },
      getOptionLabelInner(option) {
        if (this.getOptionLabel) {
          return this.getOptionLabel(option)
        }

        if (option instanceof Object) {
          return (option[this.textKey] || '').toString()
        }

        return option
      }
    }
  }
</script>

<style>

</style>
